import styled from 'styled-components';
import { Box, Link } from '@chakra-ui/react';
import { UilInstagram } from '@iconscout/react-unicons';
import { UilLinkedinAlt } from '@iconscout/react-unicons';
import { UilTwitter } from '@iconscout/react-unicons';
import { UilGithub } from '@iconscout/react-unicons';

export const SocialLogoBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const LogoCircle = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  margin-left: 0.5rem;

  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background-color: #ffffff;

  box-shadow: 0 1.267198085784912px 1.267198085784912px #666d8014,
    0 5.068792343139648px 10.137584686279297px #666d8014;

  &:hover {
    transition: 0.1s;
    border: 1px solid;
    margin-top: -8px;
    border-color: #ebeef3;
    border-radius: 50%;

    color: radial-gradient(orange 100%);
  }
`;

export const InstagramLogo = styled(UilInstagram)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const LinkedinLogo = styled(UilLinkedinAlt)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const TwitterLogo = styled(UilTwitter)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const GithubLogo = styled(UilGithub)`
  width: 1.5rem;
  height: 1.5rem;
`;
