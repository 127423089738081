import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const ContentView = styled(Box)`
  display: flex;
  padding: 1.5rem 1rem;
  background-color: #f6f8fa;
  border-radius: 1rem;
  margin-top: 0.5rem;
  width: 100%;

  align-items: center;
`;
