import React from 'react';
import Raph1 from '../../assets/raphael.png';
import Raph2 from '../../assets/raphael2.png';
import Raph3 from '../../assets/raphael3.png';
import Can1 from '../../assets/can1.png';
import Fred1 from '../../assets/fred1.png';
import Petit from '../../assets/petitImage.png';
import { BottomImage, BigJohnLayout, FallingImage, PetitRaph } from './BigJohn.styles';

const BigJohn = () => {
  const generateRandomPosition = () => `${Math.random() * 100}vw`; // 0 ile 100% arasında bir konum
  const generateRandomDuration = () => `${Math.random() * 6 + 2}s`; // 2-5 saniye arasında hız

  // Görselleri array içinde tutalım
  const images = [Raph1, Can1, Raph2, Fred1, Raph3]; // Görselleri dizi içinde listeleyebilirsiniz

  return (
    <BigJohnLayout>
      <PetitRaph src={Petit} />
      {/* Array üzerinden geçerek FallingImage render edelim */}
      {images.map((image, index) => (
        <FallingImage
          key={index}
          src={image}
          left={generateRandomPosition()} // Rastgele x ekseni
          duration={generateRandomDuration()} // Rastgele düşme süresi
        />
      ))}
      <BottomImage src={Raph1} />
    </BigJohnLayout>
  );
};

export default BigJohn;
