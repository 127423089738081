import styled from 'styled-components';
import { Box } from '@chakra-ui/react';
import { UilEstate } from '@iconscout/react-unicons';
import { UilUserCircle } from '@iconscout/react-unicons';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import { UilMoon } from '@iconscout/react-unicons';

export const HeaderBox = styled(Box)`
  display: flex;
  background-color: white;
  max-width: 35rem;
  width: 100%;
  height: 4rem;
  position: fixed;
  overflow: hidden;
  z-index: 1;
  top: 1rem;
  padding: 1rem;

  border-radius: 12px;
  box-shadow: rgba(102, 109, 128, 0.08) 0px 1.2672px 1.2672px 0px,
    rgba(102, 109, 128, 0.08) 0px 5.06879px 10.1376px 0px;

  @media (max-width: 809px) {
    width: 96%;
    top: 0.5rem;
  }
`;

export const HomeIcon = styled(UilEstate)`
  width: 2.7rem;
  height: 2.7rem;
  color: black;
  opacity: 0.2;
  padding: 8px;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;

    background-color: #ebeef3;
    border-radius: 50%;
  }
`;

export const AboutMeIcon = styled(UilUserCircle)`
  width: 2.7rem;
  height: 2.7rem;
  color: black;
  opacity: 0.2;
  padding: 8px;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;

    background-color: #ebeef3;
    border-radius: 50%;
  }
`;

export const ProjectIcon = styled(UilClipboardAlt)`
  width: 2.7rem;
  height: 2.7rem;
  color: black;
  opacity: 0.2;
  padding: 8px;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;

    background-color: #ebeef3;
    border-radius: 50%;
  }
`;

export const ThemeIcon = styled(UilMoon)`
  width: 2.7rem;
  height: 2.7rem;
  color: black;
  opacity: 0.2;
  padding: 8px;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;

    background-color: #ebeef3;
    border-radius: 50%;
  }
`;
