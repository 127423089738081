import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

export const HomeLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const HomeView = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  max-width: 35rem;
  width: 100%;
  margin: 6rem 0.5rem;
  border-radius: 1.5rem;
  top: 1rem;
  padding: 0.5rem;

  overflow: hidden;

  box-shadow: 0 1.267198085784912px 1.267198085784912px #666d8014,
    0 5.068792343139648px 10.137584686279297px #666d8014;

  @media (max-width: 809px) {
    width: 96%;
    margin: 5rem 0.5rem;
  }
`;
