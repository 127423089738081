import { Box, ChakraProvider } from '@chakra-ui/react';
import Router from './navigation/Router';

import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Box display={'flex'} justifyContent="center">
          <Router />
        </Box>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
