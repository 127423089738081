import React, { FC } from 'react';
import {
  GithubLogo,
  InstagramLogo,
  LinkedinLogo,
  LogoCircle,
  SocialLogoBox,
  TwitterLogo
} from './SocialCard.styles';

import { JobTitle, JobMark, JobTitleText } from '../profile-card/ProfileCard.styles';
import ContentBox from '../atom/content-box/ContentBox';

const SocialCard: FC = () => {
  return (
    <ContentBox>
      <JobTitle>
        <JobMark />
        <JobTitleText>Follow Me</JobTitleText>
      </JobTitle>
      <SocialLogoBox>
        <LogoCircle href={'https://www.instagram.com/mcancati/'} target="_blank">
          <InstagramLogo />
        </LogoCircle>
        <LogoCircle href={'https://twitter.com/imcancati'} target="_blank">
          <TwitterLogo />
        </LogoCircle>
        <LogoCircle href={'https://www.linkedin.com/in/mahmutcancati/'} target="_blank">
          <LinkedinLogo />
        </LogoCircle>
        <LogoCircle href={'https://github.com/cancati'} target="_blank">
          <GithubLogo />
        </LogoCircle>
      </SocialLogoBox>
    </ContentBox>
  );
};

export default SocialCard;
