import styled, { keyframes } from 'styled-components';
import { Box, Image } from '@chakra-ui/react';

// Düşme Animasyonu
const fallAnimation = keyframes`
  0% {
    transform: translateY(-100px); /* Başlangıçta yukarıda */
    opacity: 1; /* Görünür */
  }
  100% {
    transform: translateY(100vh); /* Sayfanın altına iner */
    opacity: 1; /* Yok olur */
  }
`;

// Animasyonlu Görsel
export const FallingImage = styled(Image)<{ left: string; duration: string }>`
  position: absolute;
  top: -150px; /* Görsel başlangıç noktası */
  left: ${({ left }) => left}; /* Rastgele yatay konum */
  animation: ${fallAnimation} ${({ duration }) => duration} linear infinite; /* Rastgele hız */
  width: 200px; /* Görsel genişliği */
  height: auto;
  border-radius: 50%;
  opacity: 1;

  @media (max-width: 1024px) {
    width: 100px; /* Tablet ekranlar için daha geniş */
    left: 0;
  }
`;

export const BigJohnLayout = styled(Box)`
  display: flex;
  min-height: 100vh;
  min-width: 90%;
  position: relative; /* Absolute konumlandırma için gerekli */
  overflow: hidden; /* Görsellerin ekran dışına taşmasını engeller */

  @media (max-width: 480px) {
    min-height: 90vh;
  }
`;

export const BottomImage = styled(Image)`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 50%; /* Varsayılan genişlik */

  height: auto; /* Oran koruması için */
  left: 25%;

  @media (max-width: 1024px) {
    width: 70%; /* Tablet ekranlar için daha geniş */
    left: 0;
  }

  @media (max-width: 768px) {
    width: 90%; /* Mobil cihazlar için tam genişliğe yakın */
    left: 0;
  }

  @media (max-width: 480px) {
    width: 100%; /* Küçük ekranlarda tam genişlik */
    margin-left: 0;
    left: 0;
  }
`;

export const PetitRaph = styled(Image)`
  position: fixed; /* Sabit konumda kalmasını sağlar */
  top: 0;
  left: 50%;
  transform: translateX(-50%); /* Ortalamak için */
  z-index: 10; /* Diğer öğelerin önünde olması için */
  width: 40vh; /* Genişlik */
  height: 40vh; /* Yükseklik */
  background-image: url('../../assets/petit_ecolier.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
