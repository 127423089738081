import React, { FC } from 'react';
import { ContentView } from './ContentBox.styles';

interface ContentBoxProps {
  children: React.ReactNode;
}

const ContentBox: FC<ContentBoxProps> = ({ children }) => {
  return <ContentView>{children}</ContentView>;
};

export default ContentBox;
