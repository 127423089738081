import { Box, Image } from '@chakra-ui/react';
import { FC } from 'react';
import { HeaderBox } from './Header.styles';

const Header: FC = () => {
  return (
    <HeaderBox>
      <Box display={'flex'} flex-direction="row" justifyContent={'space-between'} width="100%">
        <Box display={'flex'} gap="0.25rem" flex-direction="row" alignItems={'center'}>
          <Image
            width={'100px'}
            padding={'0.5rem'}
            src="https://see.fontimg.com/api/renderfont4/9Y2DK/eyJyIjoiZnMiLCJoIjoxMzAsInciOjIwMDAsImZzIjo2NSwiZmdjIjoiIzAwMDAwMCIsImJnYyI6IiNGRkZGRkYiLCJ0IjoxfQ/Q2FuIENhdGk/nature-beauty-personal-use.png"
            alt="Dan Abramov"
          />
        </Box>
        {/* <Box display={'flex'} gap="0.5rem" alignItems={'center'}>
          <ThemeIcon />
          <Button height={'2rem'} color={'black'} colorScheme="gray" variant="solid">
            Email
          </Button>
        </Box>
         */}
      </Box>
    </HeaderBox>
  );
};

export default Header;
