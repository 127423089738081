import { HomeLayout, HomeView } from './Home.styles';
import Header from '../../components/header/Header';

import ProfileCard from '../../components/profile-card/ProfileCard';
import SocialCard from '../../components/social-card/SocialCard';
import FooterCard from '../../components/footer-card/FooterCard';
import { Box } from '@chakra-ui/react';

const Home = () => {
  return (
    <HomeLayout>
      <Header />
      <HomeView>
        <ProfileCard />
        <Box marginTop={'2rem'}>
          <SocialCard />
          <FooterCard />
        </Box>
      </HomeView>
    </HomeLayout>
  );
};

export default Home;
