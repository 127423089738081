import styled from 'styled-components';
import { Box, Link, Text } from '@chakra-ui/react';

export const ProfileView = styled(Box)`
  width: 100%;
  padding: 1rem;
`;

export const TitleBox = styled(Box)`
  display: flex;

  width: 100%;
  height: 1.5rem;
  align-items: center;
`;

export const JobTitle = styled(Box)`
  display: flex;

  width: 100%;
  height: 1.5rem;
  align-items: center;
`;

export const JobTitleText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  color: #353849;
`;

export const JobMark = styled(Box)`
  background-color: #a4abb8;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const HiringBox = styled(Box)`
  display: flex;
  background-color: #d0fadf;

  width: 1.5rem;
  height: 1.5rem;

  align-items: center;
  justify-content: center;

  border-radius: 50%;
`;

export const HiringCircle = styled(Box)`
  background-color: #28c780;
  width: 0.35rem;
  height: 0.35rem;

  border-radius: 50%;
`;

// PROFİLE IMAGE AND DESCRİPTİON

export const ProfileBox = styled(Box)`
  margin-top: 2.5rem;
  display: grid;

  width: 100%;

  justify-content: center;
  text-align: center;
`;

export const ImageBox = styled(Box)`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
`;

export const ProfileTitleText = styled(Text)`
  margin-top: 0.75rem;
  font-size: 36px;
  font-weight: bold;
  width: 100%;

  color: #353849;
`;

export const ProfileDescriptionBox = styled(Box)`
  margin-top: 0.75rem;
  width: 100%;
`;

export const ProfileDescriptionText = styled(Text)`
  font-size: 18px;
  font-weight: light;

  color: #666d80;
`;

export const CompanyLinkText = styled(Link)`
  color: #666d80;

  font-size: 18px;

  font-weight: light;
  background: #ccadff;
  background: radial-gradient(
    circle farthest-corner at center center,
    #ccadff 23%,
    #8f3bf2 60%,
    #442170 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
