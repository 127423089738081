import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../screens/home/Home';
import BigJohn from '../screens/big-john/BigJohn';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/big-john" element={<BigJohn />} />
    </Routes>
  );
};

export default Router;
