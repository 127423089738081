import { Box, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import ContentBox from '../atom/content-box/ContentBox';
import { UilCopyright } from '@iconscout/react-unicons';

const FooterCard: FC = () => {
  return (
    <ContentBox>
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'1rem 0'}
        alignItems={'center'}
        justifyContent={'center'}
        width="100%">
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <UilCopyright color={'#666D80'} size={'1rem'} />
          <Text color={'#666D80'} fontSize={'1rem'} ml={'0.12rem'} textAlign={'center'}>
            2023{' '}
            <Link
              color={'black'}
              fontWeight="semibold"
              href={'https://www.linkedin.com/in/mahmutcancati/'}
              target="_blank">
              Can Cati
            </Link>
          </Text>
        </Box>
      </Box>
    </ContentBox>
  );
};

export default FooterCard;
